import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Article, ArticleResolution, ArticleType } from '@wix/answers-api';
import { useExperiments, useTranslation } from '@wix/fe-essentials-standalone';
import { Box } from '@wix/design-system';
import classNames from 'classnames';
import { RenderHTML } from '../../RenderHTML';
import { ArticleSearchResultItem, VoteType } from '../../../types';
import { Vote } from '../../Vote';
import { ArticleFeedback } from '../../ArticleFeedback';
import { Context } from '../../../context';
import { useVoteType } from '../../../hooks/useVoteType';
import { EXPERIMENTS, PAGES } from '../../../constants';
import { RelatedContent } from './RelatedContent';
import { HirePartnerBanner } from './HirePartnerBanner';
import { RelatedArticles } from '../../RelatedArticles';
import { mapArticleTypeToItemType } from '../../../utils/bi';
import { StudioBanner } from './StudioBanner';
import css from './index.module.scss';

export type ArticleFooterProps = {
  article: ArticleSearchResultItem;
  isRoadmapArticle?: boolean;
  hasNoContent?: boolean;
  relatedArticles: Article[];
};

export const ArticleFooter: FunctionComponent<ArticleFooterProps> = ({
  article,
  isRoadmapArticle,
  hasNoContent,
  relatedArticles
}: ArticleFooterProps) => {
  const { t } = useTranslation();
  const [isOptedIn, setIsOptedIn] = useState(false);
  const [isOptInStatusLoading, setIsOptInStatusLoading] = useState(false);
  const voteType = useVoteType(article);
  const { answersApi, isWixStudioUser } = useContext(Context);
  const { experiments } = useExperiments({ readOnly: true });
  const isArticlePageUXChangesEnabled = experiments.enabled(EXPERIMENTS.SPECS.ARTICLE_PAGE_UX_CHANGES);

  const toggleIsOptedIn = () => {
    setIsOptedIn(!isOptedIn);
  };

  useEffect(() => {
    void (async () => {
      if (voteType === VoteType.ENROLLMENT && article.featureEnrollment?.featureId) {
        setIsOptInStatusLoading(true);
        const { inPopulation } = await answersApi.kb.getPopulationStatus({
          populationId: article.featureEnrollment.featureId,
        });
        setIsOptedIn(inPopulation);
        setIsOptInStatusLoading(false);
      }
    })();
  }, [article.id]);

  if (!isArticlePageUXChangesEnabled && article.type === ArticleType.ARTICLE) {
    return (
      <ArticleFeedback
        articleId={article.id}
        categoryId={article.categoryId}
        key={article.id}
      />
    );
  }

  return (
    <Box
      direction="vertical"
      className={classNames(css.articleFooter, {
        [css.roadmapArticleFooter]: isRoadmapArticle,
        [css.hasNoContent]: hasNoContent,
        [css.articleFooterNew]: isArticlePageUXChangesEnabled
      })}
    >
      {article.type !== ArticleType.ARTICLE &&
        (isArticlePageUXChangesEnabled && article.resolution === ArticleResolution.RELEASED ? null :
          <Box direction="vertical">
            <Vote
              article={article}
              isOptedIn={isOptedIn}
              toggleIsOptedIn={toggleIsOptedIn}
              isOptInStatusLoading={isOptInStatusLoading}
              hasNoContent={hasNoContent}
            />

            {!isArticlePageUXChangesEnabled && isRoadmapArticle && (
              <RenderHTML
                textElement={{
                  size: 'tiny',
                  weight: 'thin',
                  className: classNames(css.featureRequestNoteMark, {
                    [css.featureRequestNoteMarkWithDivider]:
                      article.resolution === ArticleResolution.RELEASED,
                  }),
                }}
                className={css.NumberOfResultsMobile}
                html={t('roadmap.article-list.note-mark')}
              />
            )}
          </Box>
        )
      }
      {isArticlePageUXChangesEnabled && (
        <Box direction="vertical" gap="SP5">
          {article.type === ArticleType.ARTICLE && <Box className={css.articleFeedback}>
            <ArticleFeedback articleId={article.id} categoryId={article.categoryId} />
          </Box>}
          {relatedArticles.length > 0 && (<>
            <RelatedContent relatedArticles={relatedArticles} />
            <Box className={css.relatedArticles}>
              <RelatedArticles
                itemId={article.id}
                itemType={mapArticleTypeToItemType(article.type)}
                relatedArticles={relatedArticles}
                pageType={PAGES.ARTICLE}
              />
            </Box>
          </>
          )}
          {isWixStudioUser ? (
            <StudioBanner articleId={article.id} articleType={article.type} />
          ) : (
            <HirePartnerBanner articleId={article.id} categoryId={article.categoryId} />
          )}
          {isRoadmapArticle && (
            <RenderHTML
              textElement={{
                size: 'tiny',
                weight: 'thin',
                className: css.featureRequestNoteMark
              }}
              html={t('article-footer.feature-request.note-mark')}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

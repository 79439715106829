import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Box, Text, TextButton } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { ExternalLinkSmall as ExternalLinkIcon } from '@wix/wix-ui-icons-common';
import { ArticleType } from '@wix/answers-api';
import classNames from 'classnames';
import { RenderHTML } from '../../../RenderHTML';
import { BI, WIX_STUDIO_COMMUNITY_FORUM_LINK, WIX_STUDIO_DISCORD_LINK, WIX_STUDIO_SUPPORTED_LANGUAGES } from '../../../../constants';
import { useBI } from '../../../../hooks/useBI';
import { mapArticleTypeToItemType } from '../../../../utils/bi';
import { ItemOnPageEventSource, useBiOnVisibleItem } from '../../../../hooks/useBiOnVisibleItem';
import { isMobile } from '../../../../utils/userAgent';
import { Context } from '../../../../context';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import css from './index.module.scss';
import { DATA_HOOKS } from '../../../../dataHooks';

enum StudioBannerType {
  FORUM = 'FORUM',
  DISCORD = 'DISCORD',
}

export type StudioBannerProps = {
  articleId: string;
  articleType: ArticleType;
};

const ITEM_ON_PAGE_SOURCE_MAP = {
  [StudioBannerType.DISCORD]:
    ItemOnPageEventSource.STUDIO_COMMUNITY_WISHLIST_ENTRY,
  [StudioBannerType.FORUM]: ItemOnPageEventSource.STUDIO_DISCORD,
};

export const StudioBanner: FunctionComponent<StudioBannerProps> = ({ articleId, articleType }: StudioBannerProps) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();
  const type = articleType === ArticleType.FEATURE_REQUEST ? StudioBannerType.DISCORD : StudioBannerType.FORUM;
  const { itemRef } = useBiOnVisibleItem(ITEM_ON_PAGE_SOURCE_MAP[type], { item_id: articleId });
  const context = useContext(Context);
  const [isMobileView, setIsMobileView] = useState(isMobile(context));
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width) {
      setIsMobileView(isMobile(context));
    }
  }, [windowSize.width]);

  const onClick = useCallback(async () => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ARTICLE,
        kb_lang: locale as string,
        item_id: articleId,
        is_page_content_click: false,
        item_type: mapArticleTypeToItemType(articleType),
        clicked_item_type: type === StudioBannerType.FORUM ? BI.CLICKED_ITEM_TYPES.WIX_STUDIO_COMMUNITY_FORUM : BI.CLICKED_ITEM_TYPES.WIX_STUDIO_DISCORD,
        clicked_url: getCTALink(),
        clicked_text: getCTAText()
      })
    );
  }, [articleId, locale]);

  const getCTALink = () => {
    if (type === StudioBannerType.DISCORD) {
      return WIX_STUDIO_DISCORD_LINK;
    } else {
      return WIX_STUDIO_COMMUNITY_FORUM_LINK;
    }
  };

  const getTitle = () => {
    if (type === StudioBannerType.DISCORD) {
      return t('studio-banner.studio-discord-banner.title');
    } else {
      return t('studio-banner.studio-forum-banner.title');
    }
  };

  const getDescription = () => {
    if (type === StudioBannerType.DISCORD) {
      return t('studio-banner.studio-discord-banner.description');
    } else {
      return t('studio-banner.studio-forum-banner.description');
    }
  };

  const getDescriptionTitle = () => {
    if (type === StudioBannerType.DISCORD) {
      return t('studio-banner.studio-discord-banner.mobile-description-title');
    } else {
      return t('studio-banner.studio-forum-banner.mobile-description-title');
    }
  };

  const getCTAText = () => {
    if (type === StudioBannerType.DISCORD) {
      return t('studio-banner.studio-discord-banner.cta');
    } else {
      return t('studio-banner.studio-forum-banner.cta');
    }
  };

  const getImage = () => {
    if (type === StudioBannerType.DISCORD) {
      return '/images/studio-banner/studio-discord-banner.svg';
    } else {
      return '/images/studio-banner/studio-forum-banner.svg';
    }
  };

  if (!WIX_STUDIO_SUPPORTED_LANGUAGES.includes(locale as string)) {
    return null;
  }

  return isMobileView ? (
    <Box className={css.studioBannerMobile} direction="vertical" dataHook={DATA_HOOKS.STUDIO_BANNER} ref={itemRef}>
      <Box className={classNames(css.headerTitle, {
        [css.headerTitleDiscord]: type === StudioBannerType.DISCORD,
        [css.headerTitleForum]: type === StudioBannerType.FORUM
      })}>
        <RenderHTML textElement={{ size: 'medium' }} html={getTitle()} />
      </Box>
      <Box className={css.separator}>
        <Box className={classNames({
          [css.separatorMiddleFirstDiscord]: type === StudioBannerType.DISCORD,
          [css.separatorMiddleFirstForum]: type === StudioBannerType.FORUM,
        })} />
        <Box />
        <Box className={classNames({
          [css.separatorMiddleThirdDiscord]: type === StudioBannerType.DISCORD,
          [css.separatorMiddleThirdForum]: type === StudioBannerType.FORUM,
        })} />
      </Box>
      <Box className={css.content} direction="vertical">
        <Text className={css.contentTitle}>{getDescriptionTitle()}</Text>
        <Text className={css.contentDescription}>{getDescription()}</Text>
        <TextButton target="_blank" weight="thin" onClick={onClick} as="a" href={getCTALink()} suffixIcon={<ExternalLinkIcon />}>
          {getCTAText()}
        </TextButton>
      </Box>
      <Box className={css.footer}>
        <Image alt='' src={getImage()} height={80} width={80} />
        <Box className={classNames(css.footerMiddle, {
          [css.footerMiddleDiscord]: type === StudioBannerType.DISCORD,
          [css.footerMiddleForum]: type === StudioBannerType.FORUM
        })} />
        <Box className={css.footerEnd} />
      </Box>
    </Box>
  ) : (
    <Box className={css.studioBanner} direction="vertical" dataHook={DATA_HOOKS.STUDIO_BANNER} ref={itemRef}>
      <Box className={css.header}>
        <Box className={classNames(css.headerTitle, {
          [css.headerTitleDiscord]: type === StudioBannerType.DISCORD,
          [css.headerTitleForum]: type === StudioBannerType.FORUM
        })}>
          <RenderHTML textElement={{ size: 'medium' }} html={getTitle()} />
        </Box>
        <Box className={classNames(css.headerMiddle, {
          [css.headerMiddleDiscord]: type === StudioBannerType.DISCORD,
          [css.headerMiddleForum]: type === StudioBannerType.FORUM
        })}></Box>
        <Image alt='' src={getImage()} height={122} width={122} />
      </Box>
      <Box className={css.content} gap={2} direction="vertical">
        <Text>{getDescription()}</Text>
        <TextButton target="_blank" onClick={onClick} as="a" href={getCTALink()} suffixIcon={<ExternalLinkIcon />}>
          {getCTAText()}
        </TextButton>
      </Box>
    </Box>
  );
};

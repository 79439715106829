import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from '@wix/wix-i18n-config';
import Image from 'next/image';
import { Box, Text, TextButton } from '@wix/design-system';
import { ExternalLinkSmall as ExternalLinkIcon } from '@wix/wix-ui-icons-common';
import { Context } from '../../../../context';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { getContent } from '../../../ArticleSidebar/HirePartner/contentLogic';
import { ItemOnPageEventSource, useBiOnVisibleItem } from '../../../../hooks/useBiOnVisibleItem';
import { DATA_HOOKS } from '../../../../dataHooks';
import css from './index.module.scss';

type HirePartnerBannerProps = {
  articleId: string;
  categoryId: string;
};

export const HirePartnerBanner: FunctionComponent<HirePartnerBannerProps> = ({ articleId, categoryId }: HirePartnerBannerProps) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { isMobile } = useContext(Context);
  const windowSize = useWindowSize();
  const [isMobileView, setIsMobileView] = useState<boolean>(isMobile);
  const { itemRef, resetItemOnPageEvent } = useBiOnVisibleItem(ItemOnPageEventSource.HIRE_A_PARTNER,
    {
      item_id: articleId,
    });

  const content = getContent(articleId, categoryId, locale as string);
  useEffect(() => {
    resetItemOnPageEvent();
  }, [articleId, resetItemOnPageEvent]);

  useEffect(() => {
    if (windowSize.width) {
      setIsMobileView(windowSize.width <= 767);
    }
  }, [windowSize.width]);

  const HIRE_PARTNER_BANNER_IMAGE =
    'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/hire-partner-banner/hire-partner-banner.jpg';

  if (content === null) {
    return null;
  }

  return !isMobileView ? (
    <Box className={css.hirePartnerBanner} ref={itemRef} dataHook={DATA_HOOKS.HIRE_PARTNER}>
      <Box direction="vertical" className={css.hirePartnerBannerContent}>
        <Text dataHook={DATA_HOOKS.HIRE_PARTNER_TITLE} className={css.hirePartnerBannerTitle}>
          {t('hire-partner-banner.title')}
        </Text>
        <Text size="small" className={css.hirePartnerBannerDescription}>
          {t('hire-partner-banner.description')}
        </Text>
        <TextButton
          dataHook={DATA_HOOKS.HIRE_PARTNER_CTA}
          href={content.link}
          className={css.hirePartnerBannerCTA}
          size="small"
          as="a"
          target="_blank"
          suffixIcon={<ExternalLinkIcon />}>
          {t('hire-partner-banner.cta')}
        </TextButton>
      </Box>
      <Image
        alt={''}
        src={HIRE_PARTNER_BANNER_IMAGE}
        width={290}
        height={170}
        sizes="(max-width: 1200px) 10px 211px"
        loading="lazy"
        quality={100}
      />
    </Box>
  ) : (
    <Box className={css.hirePartnerBanner} direction="vertical" ref={itemRef} dataHook={DATA_HOOKS.HIRE_PARTNER}>
      <Text dataHook={DATA_HOOKS.HIRE_PARTNER_TITLE} className={css.hirePartnerBannerTitle}>
        {t('hire-partner-banner.title')}
      </Text>
      <Image
        alt={''}
        src={HIRE_PARTNER_BANNER_IMAGE}
        sizes="(max-width: 1200px) 10px 211px"
        loading="lazy"
        quality={100}
        fill
      />
      <Text size="small" className={css.hirePartnerBannerDescription}>
        {t('hire-partner-banner.description')}
      </Text>
      <TextButton
        dataHook={DATA_HOOKS.HIRE_PARTNER_CTA}
        href={content.link}
        className={css.hirePartnerBannerCTA}
        size="small"
        as="a"
        target="_blank"
        suffixIcon={<ExternalLinkIcon />}>
        {t('hire-partner-banner.cta')}
      </TextButton>
    </Box>
  );
};

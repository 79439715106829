import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, Heading, Text } from '@wix/design-system';
import { useRouter } from 'next/router';
import { useTranslation } from '@wix/wix-i18n-config';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { ArticleType } from '@wix/answers-api';
import { useExperiments } from '@wix/fe-essentials-standalone';
import classNames from 'classnames';
import { BI, EXPERIMENTS } from '../../../constants';
import { useBI } from '../../../hooks/useBI';
import { mapArticleTypeToItemType } from '../../../utils/bi';
import { DATA_HOOKS } from '../../../dataHooks';
import { Step } from '../../../pages/article/[articleTitle]';
import { getTopOffsetForScrollToArticlePage } from '../../../utils/scroll';
import css from './index.module.scss';

type ScrollSpyProps = {
  articleId: string;
  articleType: ArticleType;
  steps: Step[];
};

export const ScrollSpy: FunctionComponent<ScrollSpyProps> = ({
  articleId,
  articleType,
  steps,
}: ScrollSpyProps) => {
  const { t } = useTranslation();
  const { sendBIEvent } = useBI();
  const [activeStepId, setActiveStepId] = useState<string>('');
  const { locale } = useRouter();
  const { experiments } = useExperiments({ readOnly: true });
  const isArticlePageUXChangesEnabled = experiments.enabled(EXPERIMENTS.SPECS.ARTICLE_PAGE_UX_CHANGES);

  const onScroll = useCallback(() => {
    for (let i = 0; i < steps.length; i++) {
      const stepElement = document?.getElementById(steps[i].id);
      const scrollAndHeaderHeight =
        window.scrollY + getTopOffsetForScrollToArticlePage();
      if (scrollAndHeaderHeight >= (stepElement?.offsetTop as number) - 10) {
        if (i === steps.length - 1) {
          setActiveStepId(steps[i].id);
          return;
        } else {
          const nextStepElement = document?.getElementById(steps[i + 1].id);
          if (
            scrollAndHeaderHeight <=
            (nextStepElement?.offsetTop as number) - 10
          ) {
            setActiveStepId(steps[i].id);
            return;
          }
        }
      }
    }
  }, [steps]);

  const onStepLinkClick = async (
    text: string,
    order: string,
    stepId: string
  ): Promise<void> => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ARTICLE,
        kb_lang: locale as string,
        item_id: articleId,
        item_type: mapArticleTypeToItemType(articleType),
        clicked_item_type: BI.CLICKED_ITEM_TYPES.SIDEBAR_ANCHOR,
        clicked_text: text,
        clicked_item_order: order,
        is_page_content_click: true,
      })
    );
    const activeStepOffsetTop =
      document?.getElementById(stepId)?.offsetTop || 0;
    window.scrollTo({
      behavior: 'smooth',
      top: activeStepOffsetTop - getTopOffsetForScrollToArticlePage(),
    });
    const tabs = new URLSearchParams(window.location.search).get('tabs');
    history.pushState(
      null,
      '',
      `${location.pathname}${tabs ? `?tabs=${tabs}` : ''}#${stepId}`
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return steps.length > 0 ? (
    <Box
      direction="vertical"
      className={classNames(`${css.stickyWrapper} sticky-wrapper-agent`, { [css.stickyWrapperNew]: isArticlePageUXChangesEnabled })}
    >
      <Heading size="medium" as="h2" className={css.title}>
        {t('scrollspy.title')}
      </Heading>
      <ul className={css.stepsWrapper}>
        <div className={css.stepsLine} />
        {steps.map((step, index) => (
          <li
            key={step.id}
            className={css[`level-${step.level}`]}
            onClick={async () => {
              if (activeStepId !== step.id) {
                await onStepLinkClick(
                  step.text as string,
                  `${index + 1}`,
                  step.id
                );
              }
            }}
          >
            <div>
              <span
                className={`${css.stepBullet} ${activeStepId === step.id ? css.activeStepBullet : ''
                  }`}
              >
                <span className={css.activeIndicator} />
              </span>
              {index === steps.length - 1 ? (
                <span className={css.lastStepClear} />
              ) : null}
              <Text
                size="small"
                dataHook={DATA_HOOKS.SCROLL_SPY_ANCHOR}
                className={
                  activeStepId === step.id
                    ? css.stepLinkTextActive
                    : css.stepLinkText
                }
              >
                {step.text}
              </Text>
            </div>
          </li>
        ))}
      </ul>
    </Box>
  ) : null;
};

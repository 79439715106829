import { Article } from '@wix/answers-api';
import { Box } from '@wix/design-system';
import { FunctionComponent } from 'react';
import { useExperiments } from '@wix/fe-essentials-standalone';
import classNames from 'classnames';
import { EXPERIMENTS, PAGES } from '../../constants';
import { Step } from '../../pages/article/[articleTitle]';
import { mapArticleTypeToItemType } from '../../utils/bi';
import { RelatedArticles } from '../RelatedArticles';
import { HirePartner } from './HirePartner';
import { ScrollSpy } from './ScrollSpy';
import { StudioEntryBanner } from './StudioEntryBanner';
import { ArticleFeedback } from '../ArticleFeedback';
import css from './index.module.scss';

type ArticleSideBarProps = {
  article: Article;
  steps: Step[];
  isWixStudioUser: boolean;
  relatedArticles: Article[];
};

export const ArticleSideBar: FunctionComponent<ArticleSideBarProps> = ({
  article,
  steps,
  isWixStudioUser,
  relatedArticles,
}: ArticleSideBarProps) => {
  const { experiments } = useExperiments({ readOnly: true });
  const isArticlePageUXChangesEnabled = experiments.enabled(EXPERIMENTS.SPECS.ARTICLE_PAGE_UX_CHANGES);

  return (
    <Box direction="vertical" className={classNames(css.wrapper, {
      [css.stickyWrapper]: isArticlePageUXChangesEnabled,
    })}>
      <ScrollSpy
        articleId={article.id}
        articleType={article.type}
        steps={steps}
      />
      {isArticlePageUXChangesEnabled ?
        <>
          <ArticleFeedback articleId={article.id} categoryId={article.categoryId} />
        </>
        :
        <>
          <RelatedArticles
            itemId={article.id}
            itemType={mapArticleTypeToItemType(article.type)}
            relatedArticles={relatedArticles}
            pageType={PAGES.ARTICLE}
          />
          {isWixStudioUser ? (
            <StudioEntryBanner articleId={article.id} articleType={article.type} />
          ) : (
            <HirePartner articleId={article.id} categoryId={article.categoryId} />
          )}
        </>
      }
    </Box>
  );
};
